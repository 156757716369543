import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { FaHistory } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { InlineLinkButton } from "../../components/Button/button";
import Collapse from "../../components/Collapse";
import { ManageScroll } from "../../components/ManageScroll";
import { withActiveSubscrption } from "../../contexts/auth.context";
import { Chat } from "../../models/Chat.model";
import { startChat, useChat, useChats } from "../../services/chat-services";
import { useChatModels } from "../../services/ref-data-services";
import AppPageLayout from "../DocumentPage/app-page-layout";
import ChatViewPage, { ErrorChatViewPage } from "./chat-view-page";
import SearchInputLexebraGPT from "./gpt-input";
import "./landing-page.scss";

const SearchLexebraGPTLanding = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const { chatId } = useParams();
  const chat = useChat(chatId);

  const handleClearSearch = () => {
    setInputValue("");
    setError("");
    navigate("/lexebragpt-search");
  };

  const { data: chatModels } = useChatModels();
  const defaultChatModel = chatModels?.[0];

  const performSearch = useCallback(
    async (searchText: string) => {
      if (!searchText) {
        return;
      }
      setSubmitting(true);
      startChat({ query: searchText, model: defaultChatModel! })
        .then((chat) => {
          setSubmitting(false);
          setError("");
          navigate(pathname + `/${chat.id}`);
        })
        .catch((e) => {
          console.log(e);
          if (e.payload?.code === "LEX-001") {
            setError(e.payload.message);
          } else {
            toast.error("Възникна грешка при генерирането на отговора. Моля, опитайте отново.");
          }
          setSubmitting(false);
        });
    },
    [defaultChatModel, navigate, pathname]
  );

  useEffect(() => {
    setInputValue(chat?.query || "");
  }, [chat?.query]);

  return (
    <AppPageLayout
      title={intl.formatMessage({ id: "landing-page.title" })}
      className="search-landing-page"
      navigation={<SearchLexebraGPTNavigation />}
    >
      <ManageScroll />

      <SearchInputLexebraGPT
        inputValue={inputValue}
        setInputValue={setInputValue}
        onSearch={() => performSearch(inputValue)}
        submitting={submitting}
        newSearchEnabled={!!chatId}
        onClearSearch={handleClearSearch}
      />
      {/* {!hasSearched && (
                <SampleSearchCards setInputValue={setInputValue} onSearch={performSearch} />
              )} */}
      <div className="search-results-container">
        {error && <ErrorChatViewPage content={error} query={inputValue} />}
        <ChatViewPage loading={submitting} chat={chat} query={inputValue} />
      </div>
    </AppPageLayout>
  );
};

const splitChatsByDate = (chats?: Chat[]) => {
  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");

  const fromToday: Chat[] = [];
  const fromYesterday: Chat[] = [];
  const fromLastTenDays: Chat[] = [];

  chats?.forEach((chat) => {
    const chatDate = dayjs(chat.createdOn);

    if (chatDate.isSame(today, "day")) {
      fromToday.push(chat);
    } else if (chatDate.isSame(yesterday, "day")) {
      fromYesterday.push(chat);
    } else {
      fromLastTenDays.push(chat);
    }
  });

  return { fromToday, fromYesterday, fromLastTenDays };
};

const SearchLexebraGPTNavigation = () => {
  const [searchInput, setSearchInput] = useState("");
  const { data: chats } = useChats("recent");

  const { fromToday, fromYesterday, fromLastTenDays } = useMemo(
    () => splitChatsByDate(chats),
    [chats]
  );

  const filteredSearchHistoryToday = fromToday.filter((item) =>
    item.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredSearchHistoryYesterday = fromYesterday.filter((item) =>
    item.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredSearchHistoryLast10Days = fromLastTenDays.filter((item) =>
    item.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const navigate = useNavigate();
  const onClick = (chat: Chat) => async () => {
    navigate(`/lexebragpt-search/${chat.id}`);
  };

  return (
    <Container
      fluid
      className="filters-sidebar"
      style={{
        boxShadow: "2px 4px 8px var(--search-page-landing-page-container-border-shadow)",
      }}
    >
      <Collapse icon={<FaHistory />} label="История на търсенията">
        <Form.Control
          type="text"
          placeholder="Търсене..."
          className="mt-2 mb-2"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />

        {filteredSearchHistoryToday.length > 0 && (
          <>
            <small className="text-muted" style={{ marginLeft: "15px" }}>
              Днес
            </small>
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {filteredSearchHistoryToday.map((searchItem, index) => (
                <ListGroupItem key={index}>
                  <InlineLinkButton onClick={onClick(searchItem)}>
                    {searchItem.title}
                  </InlineLinkButton>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}

        {filteredSearchHistoryYesterday.length > 0 && (
          <>
            <small className="text-muted" style={{ marginLeft: "15px" }}>
              Вчера
            </small>
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {filteredSearchHistoryYesterday.map((searchItem, index) => (
                <ListGroupItem key={index}>
                  <InlineLinkButton onClick={onClick(searchItem)}>
                    {searchItem.title}
                  </InlineLinkButton>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}

        {filteredSearchHistoryLast10Days.length > 0 && (
          <>
            <small className="text-muted" style={{ marginLeft: "15px" }}>
              Последните 10 дни
            </small>
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {filteredSearchHistoryLast10Days.map((searchItem, index) => (
                <ListGroupItem key={index}>
                  <InlineLinkButton onClick={onClick(searchItem)}>
                    {searchItem.title}
                  </InlineLinkButton>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}
      </Collapse>
    </Container>
  );
};

export default withActiveSubscrption(SearchLexebraGPTLanding);

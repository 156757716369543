import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Badge, Card, Nav, OverlayTrigger, Tab, Table, Tooltip } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight, FaRegClone, FaRegThumbsUp, FaSync } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { InlineLinkButton } from "../../components/Button/button";
import LabelValue from "../../components/label-value";
import { InlineLink } from "../../components/Link/link";
import LoadingIndicator from "../../components/LoadingIndicator/loadingIndicator";
import SimpleSelect from "../../components/SimpleSelect";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { Chat } from "../../models/Chat.model";
import { regenerateResponse, submitFeedback } from "../../services/chat-services";
import { useChatModels } from "../../services/ref-data-services";
import { summaryHref } from "../../services/summary-services";
import { useCurrentUser } from "../../services/user-services";
import ResponsiveFilter from "../SearchPage/FiltersSidebar/responsive-filter";
import "../SearchPage/SearchResults/search-results.scss";
import "./chat-view-page.scss";
import SubmitNegativeFeedback from "./submit-feedback";

const ChatViewPage: React.FC<{
  chat?: Chat;
  query: string;
  loading: boolean;
}> = ({ chat, loading: initialLoading, query }) => {
  const { setSelectedActId } = useSelectedActContext();
  const { formatMessage } = useIntl();

  const [currentResponseIdx, setCurrentResponseIdx] = useState<number>(0);
  const currentResponse = chat?.responses[currentResponseIdx];

  const { data: chatModels } = useChatModels();

  const [loading, setLoading] = useState(initialLoading);

  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);

  const currentUser = useCurrentUser();

  const intl = useIntl();

  return (
    <>
      {(!!chat || loading) && (
        <Card className={`ms-3 mb-3 me-3 search-card`}>
          <Card.Body style={{ flexDirection: "column" }}>
            {!!chat && (
              <p>
                <Card.Text className="fw-bold">Въпрос: </Card.Text>
                <Card.Text>{query || chat.title}</Card.Text>
              </p>
            )}
            <Card.Text className="fw-bold">Отговор: </Card.Text>
            <Card.Text>
              {loading ? <LoadingIndicator /> : <pre>{currentResponse?.content}</pre>}
            </Card.Text>
            {currentResponse && currentResponse?.feedbackScore !== "NEUTRAL" && (
              <>
                <Card.Text className="fw-bold">
                  Оценка:{" "}
                  <Badge bg={currentResponse?.feedbackScore === "POSITIVE" ? "success" : "danger"}>
                    {currentResponse?.feedbackScore === "POSITIVE" ? "Добър" : "Лош"}
                  </Badge>
                </Card.Text>
                <Card.Text>
                  <pre>{currentResponse?.feedbackComment}</pre>
                </Card.Text>
              </>
            )}
            {!!currentResponse && chat?.user.email !== currentUser?.email && (
              <span
                className="text-muted text-end"
                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
              >
                Генерирано от {chat?.user.firstName} {chat?.user.lastName} на{" "}
                {intl.formatDate(dayjs(chat.createdOn, "YYYY-MM-DD").toDate(), {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                })}
              </span>
            )}
            {!!currentResponse && !loading && chat.user.email === currentUser?.email && (
              <div className="d-flex gap-2 align-items-center">
                {chat.responses.length > 1 && (
                  <div className="d-flex gap-1 align-items-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => (
                        <Tooltip id="prev-answer" {...props}>
                          Предишен отговор
                        </Tooltip>
                      )}
                    >
                      <span className="copy-button ms-0">
                        <InlineLinkButton
                          disabled={currentResponseIdx === 0}
                          onClick={() => setCurrentResponseIdx((current) => current - 1)}
                        >
                          <FaAngleLeft />
                        </InlineLinkButton>
                      </span>
                    </OverlayTrigger>
                    <span>
                      {currentResponseIdx + 1}/{chat.responses.length}
                    </span>

                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => (
                        <Tooltip id="next-answer" {...props}>
                          Следващ отговор
                        </Tooltip>
                      )}
                    >
                      <span className="copy-button ms-0">
                        <InlineLinkButton
                          disabled={currentResponseIdx >= chat.responses.length - 1}
                          onClick={() => setCurrentResponseIdx((current) => current + 1)}
                        >
                          <FaAngleRight />
                        </InlineLinkButton>
                      </span>
                    </OverlayTrigger>
                  </div>
                )}
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip id="copy-answer" {...props}>
                      Копирай отговора
                    </Tooltip>
                  )}
                >
                  <span className="copy-button">
                    <InlineLinkButton
                      onClick={async () => {
                        await navigator.clipboard.writeText(currentResponse?.content || "");
                        toast.success("Отговорът беше копиран успешно.");
                      }}
                    >
                      <FaRegClone />
                    </InlineLinkButton>
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip id="good-answer" {...props}>
                      Добър отговор
                    </Tooltip>
                  )}
                >
                  <span className="copy-button ms-0">
                    <InlineLinkButton
                      onClick={async () => {
                        await submitFeedback(chat.id, currentResponse.id, {
                          score: "POSITIVE",
                          comment: "",
                        });
                        toast.success("Благодарим Ви за обратната връзка.");
                      }}
                    >
                      <FaRegThumbsUp />
                    </InlineLinkButton>
                  </span>
                </OverlayTrigger>
                <SubmitNegativeFeedback chatId={chat.id} responseId={currentResponse.id} />
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip id="regenrate-answer" {...props}>
                      Регенерирай отговора
                    </Tooltip>
                  )}
                >
                  <span className="copy-button ms-0">
                    <ResponsiveFilter>
                      <SimpleSelect
                        className="inline-select"
                        options={chatModels}
                        getOption={(model) => ({
                          label: model.shortName,
                          value: model.id,
                        })}
                        value={[currentResponse.model]}
                        onChange={(value) => {
                          setLoading(true);
                          regenerateResponse(chat.id, value[0])
                            .then((result) => {
                              toast.success("Отговорът беше регенериран успешно.");
                              setLoading(false);
                              setCurrentResponseIdx(() => result.responses.length - 1);
                            })
                            .catch(() => {
                              setLoading(false);
                              toast.error("Възникна грешка при регенериране на отговора.");
                            });
                        }}
                        isMulti={false}
                        valueRenderer={(value) => (
                          <span className="d-flex align-items-center gap-1">
                            <FaSync />
                            <span className="value-label">
                              {value.map((s) => s.label).join(", ")}
                            </span>
                          </span>
                        )}
                      />
                    </ResponsiveFilter>
                  </span>
                </OverlayTrigger>
              </div>
            )}
          </Card.Body>
        </Card>
      )}
      {chat && (
        <Tab.Container defaultActiveKey={"0"}>
          <Nav variant="pills" className="opinion-tabs">
            <Nav.Item>
              <Nav.Link eventKey="0">
                <FormattedMessage id="view-summary.labels.relatedJudgementActs.questions" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="1">
                <FormattedMessage id="view-summary.labels.relatedJudgementActs.acts" />
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="0">
              <div className="results-table-container">
                <Table borderless responsive size="sm" className="results-table">
                  <tbody>
                    {chat.questions.map((item) => (
                      <tr key={item.relatedQuestion.id}>
                        <td className="main-content">
                          <div className="row-content">
                            <div className="row-main-info">
                              <InlineLink
                                to={summaryHref(
                                  `${item.relatedQuestion.summary.id}#${item.relatedQuestion.id}`
                                )}
                                onClick={(e) => {
                                  if (!e.ctrlKey && !e.metaKey) {
                                    e.preventDefault();
                                    setSelectedActId("summary:" + item.relatedQuestion.summary.id);
                                  }
                                }}
                              >
                                {item.relatedQuestion.question}
                              </InlineLink>
                              <span className="text-muted ms-1">({item.score.toFixed(2)})</span>
                              <div>{item.relatedQuestion.shortTitle}</div>
                              {item.relatedQuestion.caseType &&
                                item.relatedQuestion.caseType.code !== "CRIMINAL" &&
                                item.relatedQuestion.caseType.code !== "CRIMINAL_PRIVATE" && (
                                  <LabelValue
                                    label={formatMessage({
                                      id: "view-judgement.labels.proceedingBy",
                                    })}
                                  >
                                    {item.relatedQuestion.proceeding?.shortName}
                                  </LabelValue>
                                )}
                              {item.relatedQuestion.actDate &&
                                /\d{4}\/\d{2}\/\d{2}/.test(item.relatedQuestion.actDate) && (
                                  <LabelValue
                                    label={formatMessage({ id: "view-judgement.labels.actDate" })}
                                  >
                                    {dayjs(item.relatedQuestion.actDate, "YYYY/MM/DD").format(
                                      "DD.MM.YYYY"
                                    )}{" "}
                                    г.
                                  </LabelValue>
                                )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="1">
              <div className="act-text">Coming soon</div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      )}
    </>
  );
};

export const ErrorChatViewPage: React.FC<{
  content?: string;
  query: string;
}> = ({ content, query }) => {
  return (
    <Card className={`ms-3 mb-3 me-3 search-card`}>
      <Card.Body style={{ flexDirection: "column" }}>
        <p>
          <Card.Text className="fw-bold">Въпрос: </Card.Text>
          <Card.Text>{query}</Card.Text>
        </p>

        <Card.Text className="fw-bold">Отговор: </Card.Text>
        <Card.Text>
          <pre>{content}</pre>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ChatViewPage;
